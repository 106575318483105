import React from 'react';
import { PublicPlan } from '@wix/ambassador-pricing-plans-read-api/http';
import { useTranslation, useEnvironment } from '@wix/yoshi-flow-editor';

import { st, classes } from './Validity.st.css';
import { getPlanValidityCycle } from '../../../../../utils';

interface Props {
  plan: PublicPlan;
  highlighted: boolean;
}

export const Validity: React.FC<Props> = ({ plan, highlighted }) => {
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  return (
    <span className={st(classes.duration, { highlighted, mobile: isMobile })}>{getPlanValidityCycle(plan, t)}</span>
  );
};
